import * as React from 'react';
import format from 'date-fns/format';
import {Badge, Card} from 'react-bootstrap';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {faShoppingCart} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {zonedTimeToUtc} from 'date-fns-tz';

import './ItemCard.scss';
import ImpressionTracker from '../../analytics/impression-tracker/ImpressionTracker';
import {AutoRoShipmentItem} from '../../auto-reorders/AutoReorderClass';
import {CardItem} from '../item.class';
import {CheckBox} from '../../ui/forms/CheckBox/CheckBox';
import {ContextPosition} from '../../ui/ContextMenu/ContextMenu';
import {GaItemListName} from '../../../client/ga/ga-ecommerce.functions';
import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {ItemCardAlert, ItemCardAlerts} from './ItemCardAlerts';
import {ItemCardContextMenu} from './ItemCardContextMenu';
import {ItemToAdd} from '../../order-items/order-items.class';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {Price} from '../../pricing/Price';
import {ProductImage} from '../../ui/ProductImage';
import {ShareLinkType} from '../../share-link/share-link.class';
import {Stepper, StepperError, stepperErrorInitialState} from '../../ui/forms/Stepper/Stepper';
import {TemplateHelpers} from '../../tools/template-helpers.class';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

export interface CardOptions {
    addToList?: boolean;
    addToOrder?: boolean;
    autoReorder?: boolean;
    canEdit?: boolean;
    categoryLink?: boolean;
    contextMenu?: boolean;
    featured?: boolean;
    generateBarcode?: boolean;
    hideAddToOrder?: boolean;
    hideCheckbox?: boolean;
    hideCheckboxMobile?: boolean;
    hideSchedule?: boolean;
    inventoryControls?: boolean;
    locationLink?: boolean;
    minVal?: number;
    nextShipment?: string;
    overrideAddButtonText?: string;
    overrideAddButtonVariant?: string;
    primaryActionReplace?: boolean;
    remove?: boolean;
    showBin?: boolean;
    showInventoryControls?: boolean;
    showLastPurchased?: boolean;
    showPrice?: boolean;
    showShareLink?: boolean;
    useAbsoluteImagePath?: boolean;
}

interface ItemCardProps {
    cardItem: CardItem;
    cardOptions: CardOptions;
    componentName: GaItemListName;
    deleteAutoRoItemConfirm?: (autoRoShipmentItem: AutoRoShipmentItem, showModal: boolean) => void;
    editEnrollmentShow?: (itemNum: string) => void;
    hidden: boolean;
    index: number;
    itemCardAlert?: ItemCardAlert;
    onEnrollAutoReorder?: (itemToAdd: ItemToAdd) => void;
    onInventoryControl?: (cardItem: CardItem) => void;
    onRemove: (itemNum: string) => void;
    onReplace: (oldItemNum: string, quantity: number, categoryPath: string, shipmentDate: string) => void;
    onStepperUpdate?: (itemNum: string, qty: number) => void;
    onStepperUpdateAllowInvalid?: boolean;
    orderNumber: string;
    recordItemCheckbox: (id: string, checked: boolean) => void;
    setShareLinkModal?: Dispatch<SetStateAction<{linkId: string; linkType: ShareLinkType; show: boolean}>>;
    toggleAutoRoShipmentItemStatusConfirm?: () => void;
    unitsOrdered: number;
    user: User;
}

export const ItemCard = ({
    cardItem,
    cardOptions,
    componentName,
    deleteAutoRoItemConfirm = () => null,
    editEnrollmentShow = () => null,
    hidden,
    index,
    itemCardAlert,
    onEnrollAutoReorder,
    onInventoryControl,
    onRemove,
    onReplace,
    onStepperUpdate,
    onStepperUpdateAllowInvalid,
    orderNumber,
    recordItemCheckbox,
    setShareLinkModal,
    toggleAutoRoShipmentItemStatusConfirm = () => null,
    unitsOrdered = 1,
    user,
}: ItemCardProps) => {
    const [orderQty, setOrderQty] = useState(unitsOrdered);
    const [stepperError, setStepperError] = useState<StepperError>(stepperErrorInitialState);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const _cardOptions: CardOptions = {
        addToList: false,
        addToOrder: false,
        canEdit: false,
        contextMenu: false,
        featured: false,
        generateBarcode: false,
        hideAddToOrder: false,
        inventoryControls: false,
        minVal: 1,
        remove: false,
        showBin: true,
        showLastPurchased: false,
        showPrice: true,
        useAbsoluteImagePath: false,
        ...cardOptions,
    };

    /**
     * validate qty 0
     */
    const validateStepper = () => {
        if (orderQty === 0) {
            setStepperError({error: 'Enter valid qty', message: 'Set order quantity to 1 or greater'});
            return false;
        } else if (orderQty > cardItem.qtyLimit) {
            setStepperError({error: `Limit ${cardItem.qtyLimit}`, message: 'Order quantity limit exceeded'});
            return false;
        } else {
            setStepperError({message: '', error: ''});
            return true;
        }
    };

    useEffect(() => {
        if (_cardOptions.inventoryControls) {
            setOrderQty(unitsOrdered);
        }
    }, [unitsOrdered]);

    useEffect(() => {
        if (cardItem.checked) {
            validateStepper();
        } else {
            setStepperError({message: '', error: ''});
        }
    }, [recordItemCheckbox]);

    /**
     * Adds items to order
     * @param itemsToAdd - Items to add to the order
     * @param subComponentName
     * @private
     */
    const _addToOrder = (itemsToAdd: ItemToAdd[], subComponentName: string) => {
        orderItemsWorkflow.addToOrderModal(itemsToAdd, `${subComponentName}`, true);
    };

    /**
     * Add item to order
     * @param cardItemParam
     * @param qty
     * @param subComponentName
     */
    const addItemToOrder = (cardItemParam: CardItem, qty: number, subComponentName: GaItemListName) => {
        if (!cardItemParam) {
            return;
        }
        const itemsToAdd: ItemToAdd[] = [
            {
                item: cardItemParam.item || cardItemParam.id,
                list: subComponentName,
                unitsOrdered: qty,
            },
        ];
        _addToOrder(itemsToAdd, subComponentName);
        gaLegacyCustomEvent({
            eventAction: `add_to_order`,
            eventCategory: subComponentName as any,
            eventLabel: cardItemParam.item || cardItemParam.id,
        });
    };

    /**
     * TBD
     */
    const addToOrderClick = () => {
        if (validateStepper()) {
            if (cardOptions.primaryActionReplace) {
                onReplace(cardItem.item || cardItem.id, orderQty, cardItem.categoryPath, cardOptions.nextShipment);
            } else {
                addItemToOrder(cardItem, orderQty, componentName);
            }
        }
    };

    /**
     * TBD
     * @param props
     * @constructor
     */
    const CreateBadge = (props: {comment: string; className?: string}): JSX.Element => (
        <Badge
            className={`mt-2 font-italic caption text-reset ${props.className}`}
            style={{
                textTransform: 'unset',
            }}
            variant="light"
        >
            Comment: {props.comment}
        </Badge>
    );

    /**
     * Returns ItemCardContextMenu
     * @param position - Position to apply
     */
    const renderContextMenu = (position: ContextPosition) => (
        <ItemCardContextMenu
            addItemToOrder={addItemToOrder}
            cardItem={cardItem}
            cardOptions={cardOptions}
            componentName={componentName}
            deleteAutoRoItemConfirm={deleteAutoRoItemConfirm}
            editEnrollmentShow={editEnrollmentShow}
            index={index}
            onEnrollAutoReorder={onEnrollAutoReorder}
            onInventoryControl={onInventoryControl}
            onRemove={onRemove}
            onReplace={onReplace}
            orderQty={orderQty}
            position={position}
            setShareLinkModal={setShareLinkModal}
            toggleAutoRoShipmentItemStatusConfirm={toggleAutoRoShipmentItemStatusConfirm}
            user={user}
        />
    );

    /**
     * Returns Stepper with desired configuration
     */
    const renderStepper = () => (
        <Stepper
            className={stepperError?.error && `error`}
            disabled={cardItem.discontinued}
            maximum={cardItem.qtyLimit}
            minimum={_cardOptions.minVal}
            onError={(stepperErrorParam) => {
                setStepperError(stepperErrorParam);
            }}
            onUpdateAllowInvalid={onStepperUpdateAllowInvalid}
            onUpdate={(qty) => {
                if (onStepperUpdate) {
                    onStepperUpdate(cardItem.item || cardItem.id, qty);
                }
                setOrderQty(qty);
            }}
            value={orderQty}
        />
    );

    /**
     * Template
     */
    if (hidden || !cardItem) {
        return null;
    }
    return (
        <div id={`itemCard_${cardItem.item || cardItem.id}`}>
            <ImpressionTracker
                data={{
                    dimension16: orderNumber,
                    index,
                    item_id: cardItem.item || cardItem.id,
                    item_list_name: componentName,
                }}
                trackClicks={false}
            >
                <Card className="mb-3 isSmall">
                    <ItemCardAlerts
                        cardItem={cardItem}
                        itemCardAlert={itemCardAlert}
                        nextShipment={_cardOptions.nextShipment}
                        onReplace={onReplace}
                        orderQty={orderQty}
                        stepperError={stepperError}
                    />
                    <Card.Body className="position-relative">
                        {_cardOptions.contextMenu && renderContextMenu(`static`)}
                        {!cardOptions.hideCheckbox && (
                            <div className="mb-2">
                                <div
                                    className={`checkbox-container default${
                                        cardOptions.hideCheckboxMobile ? '  tw-hidden lg:!tw-block' : ''
                                    }`}
                                >
                                    <CheckBox
                                        alias={cardItem.item || cardItem.id}
                                        ariaLabel={`Checkbox 1 for ${cardItem.item || cardItem.id}`}
                                        name="ItemCheckBox"
                                        checked={!!cardItem.checked}
                                        className="align-self-center"
                                        onChange={(e) => {
                                            recordItemCheckbox(cardItem.item || cardItem.id, e.target.checked);
                                            if (e.target.checked) {
                                                validateStepper();
                                            }
                                        }}
                                        type="checkbox"
                                        labelClass="pb-0"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row align-items-center">
                                            <div className="col-10">
                                                {cardItem.bin && _cardOptions.showBin && (
                                                    <div className="font-weight-bold mb-1">Location: {cardItem.bin.toString()}</div>
                                                )}
                                                {cardItem.qtyLimit && (
                                                    <div className="mb-3 font-weight-bold">Max Order Qty: {cardItem.qtyLimit}</div>
                                                )}
                                                {_cardOptions.showLastPurchased && (
                                                    <div className="mb-3 font-weight-bold">
                                                        Last Ordered:&nbsp;
                                                        {cardItem?.lastInvcDt
                                                            ? format(zonedTimeToUtc(cardItem.lastInvcDt, `America/Chicago`), `MM/dd/yyyy`)
                                                            : `Not Available`}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && cardItem?.active && (
                                                    <div className="mb-3 font-weight-bold">
                                                        Next Shipment:&nbsp;
                                                        {_cardOptions.nextShipment}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && !cardItem?.active && (
                                                    <div className="mb-3 font-weight-bold">No Scheduled Shipments</div>
                                                )}
                                                {cardItem?.binQty && (
                                                    <div className="mb-3 font-weight-bold">Assortment Pkg Qty: {cardItem?.binQty}</div>
                                                )}
                                                {cardItem?.orderNumber && (
                                                    <div className="font-weight-bold">
                                                        Last Ordered: {TemplateHelpers.formatDate(cardItem?.orderDate)}
                                                    </div>
                                                )}
                                                {cardItem?.orderCount && (
                                                    <Badge
                                                        variant="light"
                                                        className="!tw-mb-4"
                                                    >
                                                        PURCHASES: {cardItem?.orderCount}
                                                    </Badge>
                                                )}
                                                <div className="caption">{`#${cardItem.prettyid || cardItem.prettyItem?.trim()}`}</div>
                                                <ImpressionTracker
                                                    as="span"
                                                    data={{
                                                        dimension16: orderNumber,
                                                        index,
                                                        item_id: cardItem.item || cardItem.id,
                                                        item_list_name: componentName,
                                                    }}
                                                    trackImpressions={false}
                                                >
                                                    <a
                                                        className="d-block"
                                                        href={`/item/${cardItem.item || cardItem.id}`}
                                                    >
                                                        {cardItem.shortdesc || cardItem.description || cardItem.name}
                                                    </a>
                                                </ImpressionTracker>
                                            </div>
                                            <div className="col-4">
                                                <ImpressionTracker
                                                    data={{
                                                        dimension16: orderNumber,
                                                        index,
                                                        item_id: cardItem.item || cardItem.id,
                                                        item_list_name: componentName,
                                                    }}
                                                    trackImpressions={false}
                                                >
                                                    <a href={`/item/${cardItem.item || cardItem.id}`}>
                                                        <ProductImage
                                                            altText={cardItem.description}
                                                            className="my-3"
                                                            size={75}
                                                            src={cardItem.imagePath}
                                                            useAbsoluteUrl={_cardOptions.useAbsoluteImagePath}
                                                        />
                                                    </a>
                                                </ImpressionTracker>
                                                {_cardOptions.inventoryControls && (
                                                    <div className="text-nowrap mb-3">Reorder Qty: {cardItem.quantity}</div>
                                                )}
                                                {cardItem.comment && _cardOptions.inventoryControls && (
                                                    <CreateBadge
                                                        className="mb-3 mt-n2"
                                                        comment={cardItem.comment}
                                                    />
                                                )}
                                            </div>
                                            <div className="col-8 text-right">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="text-right">
                                                            {cardItem?.pkgQty &&
                                                                `${cardItem?.binQty ? 'Reorder ' : ''}Pkg Qty: ${cardItem.pkgQty}`}
                                                            {cardItem?.pkgQty && cardItem?.units && ` | `}
                                                            {cardItem?.units && `Order Qty ${cardItem.units}`}
                                                        </div>
                                                        {cardItem?.qtyOrd && <div className="text-right">Total Qty: {cardItem.qtyOrd}</div>}
                                                    </div>
                                                    <div className="col-12">
                                                        {cardItem.interval && !cardOptions.hideSchedule && (
                                                            <div>Schedule: {cardItem.interval}</div>
                                                        )}
                                                        {typeof cardItem?.active === `boolean` && !_cardOptions.showPrice && (
                                                            <div className="h5 font-weight-bold">
                                                                {cardItem.active ? `Active` : `Inactive`}
                                                            </div>
                                                        )}
                                                        <div className="h5 font-weight-bold mt-0">
                                                            {_cardOptions.showPrice && (
                                                                <Price
                                                                    alias={componentName}
                                                                    doNotShowEachPrice={true}
                                                                    item={cardItem}
                                                                    user={user}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cardOptions.hideAddToOrder ? `d-none` : `col-12`}>
                                        <div className="row m-0">
                                            <div className="!tw-pl-0 col-6 col-sm-5 offset-sm-2 d-flex justify-content-end">
                                                {renderStepper()}
                                            </div>
                                            <button
                                                className={`btn btn-${
                                                    cardOptions.overrideAddButtonVariant || 'red'
                                                } btn-block col-6 col-sm-5`}
                                                disabled={cardItem.discontinued}
                                                onClick={addToOrderClick}
                                                type="button"
                                            >
                                                {cardOptions.overrideAddButtonText || `Add to Order`}
                                            </button>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-sm-5 offset-sm-2">
                                                {stepperError && <div className="red-500">{stepperError.error}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className={`mb-3 isMedium ${_cardOptions.featured ? 'isFeatured' : ''}`}>
                    <ItemCardAlerts
                        cardItem={cardItem}
                        itemCardAlert={itemCardAlert}
                        nextShipment={_cardOptions.nextShipment}
                        onReplace={onReplace}
                        orderQty={orderQty}
                        stepperError={stepperError}
                    />
                    <Card.Body className="position-relative">
                        {_cardOptions.contextMenu && renderContextMenu(`static`)}
                        {!_cardOptions.hideCheckbox && !_cardOptions.featured ? (
                            <div className={`checkbox-container top${cardOptions.hideCheckboxMobile ? '  tw-hidden lg:!tw-block' : ''}`}>
                                <CheckBox
                                    alias={cardItem.item || cardItem.id}
                                    ariaLabel={`Checkbox 2 for ${cardItem.item || cardItem.id}`}
                                    name="ItemCheckBox"
                                    checked={!!cardItem.checked}
                                    className="align-self-center"
                                    onChange={(e) => {
                                        recordItemCheckbox(cardItem.item || cardItem.id, e.target.checked);
                                        if (e.target.checked) {
                                            validateStepper();
                                        }
                                    }}
                                    type="checkbox"
                                    labelClass="pt-3"
                                />
                            </div>
                        ) : (
                            ``
                        )}
                        <div className="d-flex">
                            {cardItem && (
                                <div className="h5 flex-shrink-0 mr-3 ml-0 mb-0 mt-7">
                                    <ImpressionTracker
                                        as="span"
                                        data={{
                                            dimension16: orderNumber,
                                            index,
                                            item_id: cardItem.item || cardItem.id,
                                            item_list_name: componentName,
                                        }}
                                        trackImpressions={false}
                                    >
                                        <a href={`/item/${cardItem.item || cardItem.id}`}>
                                            <ProductImage
                                                altText={cardItem.description}
                                                className="mr-3"
                                                size={75}
                                                src={cardItem.imagePath}
                                                useAbsoluteUrl={_cardOptions.useAbsoluteImagePath}
                                            />
                                        </a>
                                    </ImpressionTracker>
                                </div>
                            )}
                            <div className="flex-grow-1">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row align-items-center">
                                            <div className="col-10">
                                                {cardItem.bin && (
                                                    <div className="font-weight-bold mb-1">Location: {cardItem.bin.toString()}</div>
                                                )}
                                                {cardItem.qtyLimit && (
                                                    <div className="mb-3 font-weight-bold">Max Order Qty: {cardItem.qtyLimit}</div>
                                                )}
                                                {_cardOptions.showLastPurchased && (
                                                    <div className="mb-3 font-weight-bold">
                                                        Last Ordered:&nbsp;
                                                        {cardItem?.lastInvcDt
                                                            ? format(zonedTimeToUtc(cardItem.lastInvcDt, `America/Chicago`), `MM/dd/yyyy`)
                                                            : `Not Available`}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && cardItem?.active && (
                                                    <div className="mb-3 font-weight-bold">
                                                        Next Shipment:&nbsp;
                                                        {_cardOptions.nextShipment}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && !cardItem?.active && (
                                                    <div className="mb-3 font-weight-bold">No Scheduled Shipments</div>
                                                )}
                                                {cardItem?.binQty && (
                                                    <div className="mb-3 font-weight-bold">Assortment Pkg Qty: {cardItem?.binQty}</div>
                                                )}
                                                {cardItem?.orderNumber && (
                                                    <div className="font-weight-bold">
                                                        Last Ordered: {TemplateHelpers.formatDate(cardItem?.orderDate)}
                                                        {cardItem?.orderNumber && (
                                                            <span>
                                                                <span className="tw-mx-2">|</span>
                                                                <a href={`/order-history/${cardItem.invoiceNumber}`}>
                                                                    #{cardItem.orderNumber}
                                                                </a>
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                                {cardItem?.orderCount && (
                                                    <Badge
                                                        variant="light"
                                                        className="!tw-mb-4"
                                                    >
                                                        PURCHASES: {cardItem?.orderCount}
                                                    </Badge>
                                                )}
                                                <div className="caption">{`#${cardItem.prettyid || cardItem.prettyItem?.trim()}`}</div>
                                                <ImpressionTracker
                                                    classes="d-inline-block mb-3 col-11 px-0"
                                                    data={{
                                                        dimension16: orderNumber,
                                                        index,
                                                        item_id: cardItem.item || cardItem.id,
                                                        item_list_name: componentName,
                                                    }}
                                                    trackImpressions={false}
                                                >
                                                    <a href={`/item/${cardItem.item || cardItem.id}`}>
                                                        {cardItem.shortdesc || cardItem.description || cardItem.name}
                                                    </a>
                                                </ImpressionTracker>
                                                {_cardOptions.inventoryControls && <div>Reorder Qty: {cardItem.quantity}</div>}
                                                {cardItem.comment && _cardOptions.inventoryControls && (
                                                    <CreateBadge
                                                        className="mb-3 mt-n2"
                                                        comment={cardItem.comment}
                                                    />
                                                )}
                                            </div>
                                            <div className="col-6 text-left">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="text-left">
                                                            {cardItem?.pkgQty &&
                                                                `${cardItem?.binQty ? 'Reorder ' : ''}Pkg Qty: ${cardItem.pkgQty}`}
                                                            {cardItem?.pkgQty && cardItem?.units && ` | `}
                                                            {cardItem?.units && `Order Qty ${cardItem.units}`}
                                                        </div>
                                                        {cardItem?.qtyOrd && <div className="text-left">Total Qty: {cardItem.qtyOrd}</div>}
                                                    </div>
                                                    <div className="col-12">
                                                        {cardItem.interval && !cardOptions.hideSchedule && (
                                                            <div className="mb-2">Schedule: {cardItem.interval}</div>
                                                        )}
                                                        {_cardOptions.showPrice && (
                                                            <div className="h5 font-weight-bold mt-0 mb-3">
                                                                <Price
                                                                    alias={componentName}
                                                                    doNotShowEachPrice={true}
                                                                    item={cardItem}
                                                                    user={user}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cardOptions.hideAddToOrder ? `d-none` : `col-12`}>
                                        <div className="row align-items-lg-center h-100">
                                            <div className="col-7 offset-5">
                                                <div className="row h-100">
                                                    <div className="col-6 d-flex justify-content-end">
                                                        <div className="d-flex flex-column">
                                                            {renderStepper()}
                                                            {stepperError && <div className="red-500">{stepperError.error}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <button
                                                            className={`btn btn-${cardOptions.overrideAddButtonVariant || 'red'} btn-block`}
                                                            disabled={cardItem.discontinued}
                                                            onClick={addToOrderClick}
                                                            type="button"
                                                        >
                                                            {cardOptions.overrideAddButtonText || `Add to Order`}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {typeof cardItem?.active === `boolean` && !_cardOptions.showPrice && (
                                        <div className="col-12 h5 font-weight-bold">{cardItem.active ? `Active` : `Inactive`}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className={`mb-3 ${_cardOptions.featured ? 'hideLarge' : 'isLarge'}`}>
                    <ItemCardAlerts
                        cardItem={cardItem}
                        itemCardAlert={itemCardAlert}
                        nextShipment={_cardOptions.nextShipment}
                        onReplace={onReplace}
                        orderQty={orderQty}
                        stepperError={stepperError}
                    />
                    <Card.Body className="position-relative">
                        {!cardOptions.hideCheckbox && (
                            <div className="checkbox-container center">
                                <CheckBox
                                    alias={cardItem.item || cardItem.id}
                                    ariaLabel={`Checkbox 3 for ${cardItem.item || cardItem.id}`}
                                    name="ItemCheckBox"
                                    checked={!!cardItem.checked}
                                    className="align-self-center"
                                    onChange={(e) => {
                                        recordItemCheckbox(cardItem.item || cardItem.id, e.target.checked);
                                        if (e.target.checked) {
                                            validateStepper();
                                        }
                                    }}
                                    type="checkbox"
                                    labelClass="pt-3"
                                />
                            </div>
                        )}
                        <div className="d-flex align-items-center">
                            {cardItem && (
                                <div className={`h5 flex-shrink-0 mr-3 ${cardOptions.hideCheckbox ? `ml-0` : `ml-8`} mb-0`}>
                                    <ImpressionTracker
                                        as="span"
                                        data={{
                                            dimension16: orderNumber,
                                            index,
                                            item_id: cardItem.item || cardItem.id,
                                            item_list_name: componentName,
                                        }}
                                        trackImpressions={false}
                                    >
                                        <a href={`/item/${cardItem.item || cardItem.id}`}>
                                            <ProductImage
                                                className="mr-3"
                                                size={75}
                                                src={cardItem.imagePath}
                                                altText={cardItem.description}
                                                useAbsoluteUrl={_cardOptions.useAbsoluteImagePath}
                                            />
                                        </a>
                                    </ImpressionTracker>
                                </div>
                            )}
                            <div className="flex-grow-1">
                                <div className="row align-items-center">
                                    <div className={cardOptions.hideAddToOrder ? `col-9` : `col-8`}>
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                {cardItem?.orderNumber && (
                                                    <div className="font-weight-bold">
                                                        Last Ordered: {TemplateHelpers.formatDate(cardItem?.orderDate)}
                                                        {cardItem?.orderNumber && (
                                                            <span>
                                                                <span className="tw-mx-2">|</span>
                                                                <a href={`/order-history/${cardItem.invoiceNumber}`}>
                                                                    #{cardItem.orderNumber}
                                                                </a>
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                                {cardItem?.orderCount && (
                                                    <Badge
                                                        variant="light"
                                                        className="!tw-mb-4"
                                                    >
                                                        PURCHASES: {cardItem?.orderCount}
                                                    </Badge>
                                                )}
                                                {cardItem.bin && (
                                                    <div className="font-weight-bold mb-1">Location: {cardItem.bin.toString()}</div>
                                                )}
                                                {cardItem.qtyLimit && (
                                                    <div className="mb-3 font-weight-bold">Max Order Qty: {cardItem.qtyLimit}</div>
                                                )}
                                                {_cardOptions.showLastPurchased && (
                                                    <div className="mb-3 font-weight-bold">
                                                        Last Ordered:&nbsp;
                                                        {cardItem?.lastInvcDt
                                                            ? format(zonedTimeToUtc(cardItem.lastInvcDt, `America/Chicago`), `MM/dd/yyyy`)
                                                            : `Not Available`}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && cardItem?.active && (
                                                    <div className="mb-3 font-weight-bold">
                                                        Next Shipment:&nbsp;
                                                        {_cardOptions.nextShipment}
                                                    </div>
                                                )}
                                                {_cardOptions.nextShipment && !cardItem?.active && (
                                                    <div className="mb-3 font-weight-bold">No Scheduled Shipments</div>
                                                )}
                                                {cardItem?.binQty && (
                                                    <div className="mb-3 font-weight-bold">Assortment Pkg Qty: {cardItem?.binQty}</div>
                                                )}
                                                <div className="caption">{`#${cardItem.prettyid || cardItem.prettyItem?.trim()}`}</div>
                                                <ImpressionTracker
                                                    classes="d-inline-block mb-md-2 mb-lg-0 col-10 col-sm-11 px-0"
                                                    data={{
                                                        dimension16: orderNumber,
                                                        index,
                                                        item_id: cardItem.item || cardItem.id,
                                                        item_list_name: componentName,
                                                    }}
                                                    trackImpressions={false}
                                                >
                                                    <a href={`/item/${cardItem.item || cardItem.id}`}>
                                                        {cardItem.shortdesc || cardItem.description || cardItem.name}
                                                    </a>
                                                </ImpressionTracker>
                                                {cardItem.comment && _cardOptions.inventoryControls && (
                                                    <CreateBadge comment={cardItem.comment} />
                                                )}
                                            </div>
                                            <div className="col-6 text-right">
                                                <div className={`row align-items-lg-center`}>
                                                    <div
                                                        className={`${
                                                            _cardOptions.inventoryControls || cardItem?.qtyOrd ? 'col-4' : 'col-6'
                                                        }`}
                                                    >
                                                        <div className="text-right">
                                                            {cardItem?.pkgQty &&
                                                                `${cardItem?.binQty ? 'Reorder ' : ''}Pkg Qty: ${cardItem.pkgQty}`}
                                                            {cardItem?.pkgQty && cardItem?.units && ` | `}
                                                            {cardItem?.units && `Order Qty ${cardItem.units}`}
                                                        </div>
                                                    </div>
                                                    {cardItem?.qtyOrd && <div className="col-4">Total Qty: {cardItem.qtyOrd}</div>}
                                                    <div
                                                        className={`${
                                                            _cardOptions.inventoryControls || cardItem?.qtyOrd ? 'col-4' : 'col-6'
                                                        }`}
                                                    >
                                                        {cardItem.interval && !cardOptions.hideSchedule && (
                                                            <div>Schedule: {cardItem.interval}</div>
                                                        )}
                                                        {_cardOptions.showPrice && (
                                                            <div className={`my-0`}>
                                                                <Price
                                                                    alias={componentName}
                                                                    doNotShowEachPrice={true}
                                                                    item={cardItem}
                                                                    user={user}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {_cardOptions.inventoryControls && (
                                                        <div className="col-4">
                                                            <div>Reorder Qty: {cardItem.quantity}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cardOptions.hideAddToOrder ? `d-none` : `col-3`}>
                                        <div className="row align-items-center h-100">
                                            <div className="col-12">
                                                <div className="row align-items-center justify-content-end h-100">
                                                    <div className="col-7 d-flex justify-content-end">
                                                        <div className="d-flex flex-column">
                                                            {renderStepper()}
                                                            {stepperError?.error && (
                                                                <div className="red-500 mb-n4">{stepperError.error}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <button
                                                            className="btn btn-red"
                                                            disabled={cardItem.discontinued}
                                                            onClick={addToOrderClick}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faShoppingCart}
                                                                className="white"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {typeof cardItem?.active === `boolean` && !_cardOptions.showPrice && (
                                        <div className="col-2 h5 font-weight-bold mb-0 text-center">
                                            {cardItem.active ? `Active` : `Inactive`}
                                        </div>
                                    )}
                                    <div className="col-1">{_cardOptions.contextMenu && renderContextMenu(`center`)}</div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </ImpressionTracker>
        </div>
    );
};
